// Palette
$neutral-1: #FFF;
$neutral-100: #EAFFFF;
$neutral-200: #D3F0F0;
$neutral-300: #CDD;
$neutral-400: #AEBCBC;
$neutral-500: #777D7E;
$neutral-600: #4A4B4C;
$neutral-800: #231F20;

$primary: #00B9F2;
$secondary: #ED135D;
$tertiary: #9747FF;
$quaternary: #DFC622;
$light-gray: $neutral-300;
$not-black: $neutral-800;

// Semantic Colors

$text-dark: #FFF;
$background-dark: $not-black;

$text-light: $not-black;
$background-light: $neutral-1;

// Font sizes
$micro: 12px;
$small: 16px;
$medium-1: 20px;
$medium: 24px;
$large: 32px;

$heading: 64pt;
$subheading: 32pt;

@mixin shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}


@mixin rounded-big {
  border-radius: 16px;
}

@mixin rounded-small {
  border-radius: 8px;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin dark-background {
  color: $text-dark;
  background-color: $background-dark;
}

