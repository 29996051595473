@use 'design_system' as ds;
@use 'light';
// @use 'dark';

a[target="_blank"]:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjEzIiBoZWlnaHQ9IjEzIiBmaWxsPSIjMWE3M2U4Ij48cGF0aCBkPSJNMTkgMTlINVY1aDdWM0g1YTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGMxLjEgMCAyLS45IDItMnYtN2gtMnY3ek0xNCAzdjJoMy41OWwtOS44MyA5LjgzIDEuNDEgMS40MUwxOSA2LjQxVjEwaDJWM2gtN3oiLz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: .8125rem;
    margin: 0 .1875rem 0 .25rem;
    position: relative;
    top: .125rem;
    width: .8125rem;
}

section a:link,
section a:visited {
  color: ds.$secondary;
  text-decoration: none;
  border-bottom: transparent 2px solid;

  &:hover {
    border-bottom: #000 2px solid;
  }
}

.hide {
  display: none;
}

body.post {
  font-size: 20px;
}

.warning-color {
  color: ds.$quaternary;
}

.font-mid {
  font-size: 32px;
  font-weight: 500;
}

.relative {
  position: relative;
}

a.wrap-link {
  // wraps a block element and its children
  text-decoration: none;
  color: inherit;
}

.carousel {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-evenly;

  & > a {
    // automatically grow anchor wrapped block elements in carousels
    flex-grow: 1;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font: {
    family: 'Jost', sans-serif;
    weight: 400;
  }
}

main {
  img {
    max-width: 100%;
  }
}

footer {
  @include ds.dark-background;

  // align center until we have more columns to show
  text-align: center;

  height: 488px;
  border-top: 4px solid ds.$primary;
  padding: 0 16px;

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }

  a {
    font-size: 16px;
    text-decoration: none;
    color: #FFF;

    &:hover {
      i {
        color: #FFF;
      }

      color: ds.$primary;
    }
  }
}

h1,
h2,
h3 {
  font: {
    family: "Jost";
    weight: 500;
  }
}

h1 {
  @include ds.shadow;

  font-size: 40px;
  padding: 10px 40px;
  border-radius: 16px;

  &:first-child {
    // collapse some of the major space at the top of the page for first headings
    margin-top: 0;
  }
}

.hero {
  display: block;
  width: 100%;
  height: 208px;
  padding: 0 16px;
}

.hero,
main,
footer
{
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow:
    -4px 0 0 #000,
    4px 0 0 #000,
    12px 0 12px rgba(0, 0, 0, 0.25),
    -12px 0 12px rgba(0, 0, 0, 0.25);
}

.box {
  @include ds.shadow;
  @include ds.rounded-big;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;

  .tags {
    margin: 8px 0;
    text-align: right;
  }

  .tag {
    padding: 2px 10px;
    border-radius: 8px;

    font: {
      size: ds.$micro;
      weight: 500;
    }
  }

  &.post {
    // posts can grow, video blocks get weird cause of thumbnails
    flex-grow: 1;

    h2 {
      @include ds.truncate;

      font-size: ds.$medium;
      padding: 16px;
      margin: 0;
    }

    p {
      padding-bottom: 50px;
    }
  }

  &.video {
    // 8px + p.8px = 16px padding on the bottom
    padding-bottom: 8px;

    .thumbnail {
      position: relative;

      background-color: ds.$light-gray;
      width: 100%;
      height: 202px;

      .sequence {
        position: absolute;
        right: 0;
        bottom: 0;

        padding: 10px 24px;
        border-radius: 8px 0 0 0;

        font-size: 32px;
      }
    }

    h2 {
      @include ds.truncate;
      margin: 0 16px;
    }

    h3 {
      @include ds.truncate;
      font-size: 32px;
      margin: 0 16px;
    }

    p {
      margin-top: 8px;
    }

    .duration {
      color: ds.$neutral-500;
      margin: 0 16px;
    }
  }

  p {
    margin: 16px;
    font-weight: 400;
  }

  .tags {
    padding: 0 16px;
  }

  .cta {
    position: absolute;
    right: 0;
    bottom: 0;

    text-align: center;
    text-decoration: none;

    padding: 12px 24px;
    border-radius: 8px 0 8px;

    font: {
      family: "Jost";
      weight: 600;
      size: ds.$medium-1;
    }
  }
}

.site-offline {
  .logo {
    position: absolute;

    .stacked {
      background-size: 260px 179px;

      width: 260px;
      height: 179px;
    }

    // center hack
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 0 auto;
    text-align: center;

    p.message {
      font-size: ds.$medium;
      margin: 32px 0;
    }
  }
}

.top-nav {

  .logo-bar {
    position: relative;
    height: 80px;
    top: 0;
    width: 100%;

    z-index: 100;
  }

  a {
    display: block;
    text-decoration: none;
    padding: 12px 24px;

    &.logo {
      height: 80px - 24px;
    }
  }

  .menu-opener {
    display: none;

    &:checked ~ ul {
      transform: translate(0, 0);
      opacity: 1;
    }

    &:checked ~ .logo-bar {
      .fa-bars {
        transform: translate(0, 100%);
        opacity: 0;
      }

      .fa-xmark {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }

  .hamburger-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    font-size: 24px;
    padding: 12px 16px;

    .icon {
      // same as font size
      width: 24px;
      height: 24px;
    }

    .fa {
      // doesn't animate yet, needs some keyframe workarounds
      transition: all 0.2s ease-in-out;
      position: absolute;
    }

    .fa-bars {
      transform: translate(0, 0);
      opacity: 1;
    }

    .fa-xmark {
      transform: translate(-100%, 0);
      // transform: translate(0, -100%);
      opacity: 0;
    }

  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    position: absolute;
    height: 100vh;
    width: 100%;

    transition: all 0.15s ease-in-out;
    transform: translate(-100%, 0);
    opacity: 0;

    box-shadow: 4px 0 4px rgba(0, 0, 0, .25);

    z-index: 50;

    li {
      font: {
        family: "Jost";
        size: 24px;
        weight: 500;
      }
    }
  }
}

.dialog.box {
  margin: 40px auto;
  max-width: 800px;
  text-align: center;

  h1 {
    margin: 0;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -1px;
    border-radius: 16px 16px 0 0;
    box-shadow: none;
  }

  .icon {
    font-size: 128px;
    display: block;
    text-align: center;
  }
}

section {
  @include ds.rounded-big;

  background: ds.$neutral-100;
  color: ds.$not-black;
  padding: 16px;
}

.course-video {
  // wrapper for a course's video and its video list
  display: flex;
  flex-flow: wrap;

  section {
    flex-grow: 4;
  }
}

.video-list {
  // pad to align with video player
  padding-top: 16px;
  flex: min-content;

  font: {
    size: 20px;
    weight: 500;
  }

  h2 {
    @include ds.shadow;

    color: #FFF;
    background: ds.$not-black;
    margin: 0;
    padding: 24px;
    border-bottom: 4px solid #FFF;

    // relative makes drop shadow show
    position: relative;
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .video-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #000;
    text-decoration: none;
    background: ds.$neutral-300;
    padding: 24px;

    &.section {
      @include ds.shadow;
      // make shadow show
      position: relative;

      background: ds.$neutral-400;
    }
  }

  &:hover,
  & a:hover,
  .even {
    background: ds.$neutral-200;
  }

  .duration {
    font-size: 20px;
    color: ds.$neutral-600;
    min-width: 100px;
    text-align: right;
  }
}

// callouts
blockquote {
  padding: 32px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  position: relative;

  &:not(.error):before {
    content: '\201d';
    top: 0;
    right: 12px;
    font-weight: bold;
    font-size: 6em;
    position: absolute;
    font-family: "Times New Roman", serif;
    color: rgba(0, 0, 0, 0.7);
  }

  p {
    // undo default paragraph margin
    margin: 8px 0;
  }

  &.error {
    background-color: rgba(255, 0, 0, 0.25);

    &:before {
      content: '❌ Error';
      font-weight: bold;
    }
  }
}

@media (hover: none) {
  // touch devices only
  .carousel {
    overflow: scroll;
    flex-wrap: nowrap;
    justify-content: start;
  }

  .box {
    min-width: 240px;
  }
}

@media (min-width: 375px) {
  .box {
    min-width: 300px;
  }
}

@media (min-width: 425px) {
  .site-offline {
    .logo {
      .stacked {
        background-size: 343px 236px;
        width: 343px;
        height: 236px;
      }

      p.message {
        font-size: ds.$large;
      }
    }
  }

  .box {
    min-width: 341px; // extra 1px prevents 4 boxes in first row only
    max-width: 360px;
  }
}

@media (min-width: 768px) {
  .show-on-flyout {
    display: none;
  }

  .top-nav {
    position: relative;

    ul {
      display: flex;
      opacity: 1;
      gap: 16px;

      height: auto;
      width: auto;

      top: 50%;
      right: 16px;
      transform: translate(0, -50%);

      box-shadow: none;

      z-index: 100;

      a {
        padding: 8px 16px;
        border-radius: 10px;

        font: {
          size: 20px;
          weight: 600;
        }

      }
    }

    .hamburger-button {
      display: none
    }
  }

}

@media (min-width: 1024px) {
  .top-nav {
    ul {
      right: 24px;
      gap: 24px;

      a {
        font-size: 24px;
      }
    }
  }

  .hero {
    height: 308px;
  }
}
