@use 'design_system' as ds;
@use '../pygments/light/manni';

@mixin light-background {
  color: ds.$text-light;
  background-color: ds.$background-light;
}

@mixin primary-button {
  color: #FFF;
  background-color: ds.$primary;
}

.background {
  background-color: ds.$neutral-200;
}

body {
  @include light-background;
  background-color: ds.$not-black;

  .logo {
    .stacked {
      background-image: url('/images/smallindie/stacked-light.svg');
    }
  }
}

.hero {
  background: ds.$primary
    image-set(
      url(/images/smallindie/hero-mobile.png) 1x,
      url(/images/smallindie/hero-mobile@2x.png) 2x,
      url(/images/smallindie/hero-mobile@3x.png) 3x,
    )
    no-repeat right;
  border-bottom: ds.$not-black 2px solid;
}

h1 {
  @include ds.dark-background;
}

.course {
  // course page "courses" section
  .box {
    a:link,
    a:visited {
      color: ds.$not-black
    }
  }
}

.post main,
.box {
  @include light-background;

  .tag {
    @include ds.dark-background;
  }

  &.post h2 {
    color: #FFF;
    background-color: ds.$secondary;
    border-bottom: 4px solid ds.$not-black;
  }

  &.video {
    .thumbnail {
      $color: ds.$secondary;
      border-bottom: 4px solid $color;

      .sequence {
        background: $color;
        color: #FFF;
      }
    }

    h2 {
      color: ds.$secondary;
    }
  }

  a.cta,
  a.cta:visited {
    @include primary-button;

    &:hover {
      border-bottom: 0;
    }
  }
}

main {
  padding: 0 16px;
  background-image: url(/images/smallindie/tiled-background-light.png);
}

.top-nav {

  ul {
    background-color: #fff;
  }

  .hamburger-button {
    background-color: transparent;
    color: #FFF;

    &:hover {
      background-color: transparent;
      color: #FFF
    }
  }

  ul a {
    background: #fff;
    color: ds.$not-black;

    &:link:active,
    &:visited:active {
      color: #FFF;
      background-color: ds.$primary;
    }

    &:hover {
      color: #FFF;
      background-color: ds.$primary;
    }

    &.active {
      color: #fff;
      background-color: ds.$secondary;
    }

  }

  .logo {
    @include ds.shadow;

    text-indent: -1000px;
    overflow: hidden;
    background: ds.$not-black url(/images/smallindie/inline-dark.svg) no-repeat center left 16px;
    border-bottom: 2px solid ds.$light-gray;
  }
}

div.highlight {
  @include ds.rounded-big;

  padding: 12px;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, .25) 0 2px 10px;
  overflow: scroll;
}

@media(min-width: 768px) {
  .top-nav {
    ul {
      background-color: ds.$not-black;
      a {
        background-color: ds.$not-black;
        color: #FFF;

        &:hover {
          color: ds.$not-black;
          background-color: ds.$light-gray;
        }
      }
    }
  }

}

@media(min-width: 1024px) {
  .hero {
    background-image: image-set(
        url(/images/smallindie/hero-desktop-small.png) 1x,
        url(/images/smallindie/hero-desktop-small@2x.png) 2x,
        url(/images/smallindie/hero-desktop-small@3x.png) 3x,
      )
      ;
  }
}

